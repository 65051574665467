.support-page-container {
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.support-title {
  text-align: center;
  margin-bottom: 40px;
}

.section-title {
  margin-top: 30px;
  margin-bottom: 20px;
  color: #333;
}

.faq-section,
.help-section,
.contact-info {
  margin-bottom: 30px;
}

.feedback-form {
  margin-top: 20px;
}

.feedback-form .MuiTextField-root {
  margin-bottom: 20px;
}

.feedback-form .MuiButton-root {
  background-color: #007bff;
  color: white;
}
