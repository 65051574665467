.banner-text-container {
  height: 250px;
  position: relative;
  z-index: 1;
  color: white;
}

.welcome-text {
  font-size: 64px;
  line-height: 1.2;
  margin: 0;
  text-align: left;
  padding-left: 10px;
  width: 350px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  font-weight: bold;
}

.title-header {
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 2rem;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(145deg, #6db3f2, #1e5799);
  display: inline;
  border: none; /* Remove default button border */
  box-shadow: none; /* Remove shadow if not desired */
  cursor: pointer; /* Ensure it's recognizable as a clickable button */
  transition: transform 0.3s, background-color 0.3s; /* Smooth transition for interaction */
  animation: bounce 1.25s infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border: 6px solid #6db3f2;
  border-radius: 20px;
  max-width: 400px;
  background-color: white;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50; /* High z-index to ensure visibility on top of other elements */
}

.error-text {
  color: red;
  font-size: 14px;
  margin-bottom: 1rem; /* Increased margin for better visual separation */
}

.join-class-form {
  width: 100%;
  margin-top: 1rem; /* Add space above the form */
}

/* Buttons styling to match the rest of the UI */
.join-class-button,
.create-class-button {
  background: linear-gradient(145deg, #6db3f2, #1e5799);
  color: white;
  border-radius: 0.75rem;
  cursor: pointer;
  font-size: 1rem; /* Adjusted for consistency */
  padding: 0.75rem 1.25rem;
  margin-top: 0.625rem; /* Uniform margin for vertical spacing */
  transition: transform 0.3s, background-color 0.3s;
}

.join-class-button:hover,
.create-class-button:hover {
  background-color: #154362;
  transform: scale(1.05);
}

.separator-text {
  margin: 20px 0; /* Keep uniform margin */
  text-align: center; /* Ensure center alignment for visual balance */
  color: #666; /* Soften the color for better readability */
}

/* Adjust the class creation and joining interaction area */
.create-class-button {
  margin-top: 0.75rem; /* Ensure sufficient spacing between buttons */
}

/* Enhancing the visibility and interactivity of form elements */
#roomId {
  margin-top: 0.5rem; /* Additional top margin for alignment */
}
