.photo-banner-container {
  position: relative;
}

.photo-banner img {
  transition: opacity 0.5s ease-in-out;
  width: 100%;
  height: 93.5vh;
  object-fit: cover;
  display: block;
  position: absolute;
}

.photo-banner img.hidden {
  opacity: 0;
}

.photo-banner img.visible {
  opacity: 1;
}
