/* Main container */
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.25rem;
  max-width: 100%;
}

/* Control center for buttons */
.control-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 1.25rem;
  width: 80%;
  flex-wrap: wrap;
}

.deadline-picker {
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  max-width: 250px;
}

/* Headers */
h1,
h2 {
  text-align: center;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #232323;
  padding: 0.9375rem;
}

/* Styled buttons */
.randomize-groups-button,
.smart-match-button,
.counter-button {
  background: linear-gradient(145deg, #6db3f2, #1e5799);
  color: white;
  border-radius: 0.75rem;
  cursor: pointer;
  font-size: 2rem;
  padding: 0.75rem 2.25rem;
  margin: 0.625rem;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s, background-color 0.3s;
}

/* Button hover effect */
.randomize-groups-button:hover,
.smart-match-button:hover,
.counter-button:hover {
  background-color: #154362;
  transform: scale(0.9);
}

.save-groups-button {
  background: linear-gradient(145deg, #b55151, #5a1212);
  color: white;
  border-radius: 0.75rem;
  cursor: pointer;
  font-size: 2rem;
  padding: 0.75rem 2.25rem;
  margin: 0.625rem;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s, background-color 0.3s;
}

.save-groups-button:hover {
  background-color: #154362;
  transform: scale(0.9);
}

/* Members section */
#Members {
  background-color: #f8f8f8;
  border-radius: 0.625rem;
  margin: 1.25rem;
  border: 0.125rem solid #ccc;
  padding: 1.25rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.3);
  width: 18.75rem;
  flex-shrink: 0;
  height: calc(75vh - 2.5rem);
  overflow-y: auto;
  position: fixed;
  right: 1.25rem;
  top: 25vh;
  right: -20rem;
  transition: right 0.5s, box-shadow 0.3s;
  z-index: 1;
}

/* Members section headers */
#Members h1 {
  color: #888;
  font-size: 0.875rem;
  margin-top: 0.3125rem;
  font-style: italic;
}

/* Members section titles */
#Members h3 {
  margin: 0.625rem;
  padding: 0.625rem;
  border-radius: 0.5rem;
  font-size: 1.375rem;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(145deg, #6db3f2, #1e5799);
  display: inline;
}

/* Members section list */
#Members ul {
  padding: 0;
  list-style-type: none;
}

/* Members section list items */
#Members li {
  background-color: #ffffff;
  border: 0.0625rem solid #ddd;
  margin: 0.5rem 0;
  padding: 0.625rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Button to show/hide Members section */
.show-members-btn {
  position: fixed;
  bottom: 1.25rem;
  right: 21.875rem;
  background: linear-gradient(145deg, #6db3f2, #1e5799);
  color: white;
  border: none;
  border-radius: 0.75rem;
  padding: 0.9375rem 2.1875rem;
  font-size: 1.125rem;
  cursor: pointer;
  transition: background-color 0.3s, right 0.5s;
}

.show-members-btn:hover {
  background-color: #154362;
}

/* Grid container */
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.25rem;
  padding: 0.625rem;
  margin: 0 auto;
}

/* Groups section */
#Groups {
  background-color: #f8f8f8;
  border: 0.0625rem solid #abd0e4;
  border-radius: 0.625rem;
  margin-top: 1.625rem;
  padding: 1.625rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
}

/* Groups section headers */
#Groups h3 {
  border-radius: 0.5rem;
  font-size: 2.25rem;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(145deg, #6db3f2, #1e5799);
  display: inline;

  flex: 1;
  margin: 0;
  padding: 0;
}

.group-container {
  background-color: #f8f8f8;
  border: 1px solid #abd0e4;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  margin-top: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.lock-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  color: #666;
  min-width: 24px;
  height: 24px;
  z-index: 1;
}

.lock-button.locked {
  color: #973c3c;
}

.lock-button.unlocked {
  color: #666;
}

.lock-button.locked:hover {
  color: #666;
}

.lock-button.unlocked:hover {
  color: #973c3c;
}

/* Groups section list */
#Groups ul {
  list-style: none;
  padding-left: 0;
}

/* Groups section list items */
#Groups li {
  background-color: #fff;
  margin: 0.8125rem;
  padding: 0.8125rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(7, 47, 65, 0.1);
  font-size: 1.3125rem;
  color: #333;
}

/* Unmatched Groups section */
#UnmatchedGroups {
  background-color: #fff5f5;
  border: 0.125rem dashed #db6d6d;
  border-radius: 0.625rem;
  margin-top: 1.875rem;
  padding: 1.875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  transition: filter 0.5s ease;
}

#UnmatchedGroups p {
  margin-top: 2rem;
  font-style: italic;
  color: #888;
  opacity: 0.7;
}

/* Unmatched Groups section headers */
#UnmatchedGroups h3 {
  margin: 0.9375rem;
  padding: 0.9375rem;
  border-radius: 0.5rem;
  font-size: 2.25rem;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(145deg, #f29d9d, #db6d6d);
  display: inline;
}

/* Unmatched Groups section list */
#UnmatchedGroups ul {
  list-style: none;
  padding-left: 0;
}

/* Unmatched Groups section list items */
#UnmatchedGroups li {
  background-color: #fff;
  margin: 0.9375rem;
  padding: 0.9375rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(217, 83, 79, 0.2);
  font-size: 1.3125rem;
  color: #333;
}

/* New group area */
.new-group-area,
.unmatched-members-area {
  background-color: #fff;
  border: 0.125rem dotted #ccc;
  border-radius: 0.625rem;
  padding: 0.625rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fade 0.5s infinite alternate;
}

/* New group caption */
.new-group-caption {
  color: #888;
  font-size: 0.875rem;
  margin-top: 0.3125rem;
  font-style: italic;
}

/* New group plus icon */
.new-group-plus {
  display: inline-block;
  padding: 1.25rem;
  margin-bottom: 0.625rem;
  transition: transform 0.3s, background-color 0.3s, border-color 0.3s;
  position: relative;
}

.new-group-plus svg {
  fill: #727477;
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0.0625rem 0.125rem 0.25rem #acbbc3);
}

/* Draggable items */
.draggable-item-professor,
.draggable-item-student {
  cursor: grab;
  transition: transform 0.3s;
}

/* Draggable item hover effect */
.draggable-item-professor:hover,
.draggable-item-student:hover {
  transform: scale(1.1);
}

/* Dragging item opacity */
.dragging-item {
  opacity: 0.25;
}

/* Group controls */
.group-controls {
  background-color: #f8f8f8;
  border: 0.125rem solid #b1bdc8;
  border-radius: 0.625rem;
  margin: 0.625rem;
  padding: 0.625rem;
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* Size counter */
.size-counter {
  display: flex;
  grid-template-areas: 'title title' 'value buttons';
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  margin: 0.625rem 0px 0.625rem 3%;
  border: 0.125rem solid #b1bdc8;
  padding: 0.625rem;
  border-radius: 0.5rem;
  margin: 0.625rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
}

/* Delete button hover effect */
.delete-button:hover {
  color: red;
}

/* Counter value */
.counter-value {
  background-color: #ffffff;
  color: #0e3057;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0.3125rem 0.3125rem 0.3125rem 0.125rem rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem 0 0.75rem;
  transition: transform 0.3s ease-out, background-color 0.3s, color 0.3s,
    box-shadow 0.3s;
  transform: scale(1);
}

.counter-value-change {
  animation: scaleAndShadow 0.3s ease-out forwards;
}

/* Counter title */
.counter-title {
  color: #303c48;
  font-size: 1.5rem;
  font-weight: bold;
  display: block;
  text-align: center;
  width: 100%;
  padding: 0.5rem;
}

.counter-title-live {
  color: #303c48;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  text-align: center;
  width: 100%;
  padding: 0.5rem;
}

/* Class info */
.class-info {
  margin: 0.625rem;
  padding: 0.625rem;
  border-radius: 0.5rem;
  font-size: 1.75rem;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(145deg, #6db3f2, #1e5799);
  display: inline;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleAndShadow {
  0% {
    transform: scale(1);
    box-shadow: 0.3125rem 0.3125rem 0.3125rem 0.125rem rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0.3125rem 0.3125rem 0.3125rem 0.125rem rgba(171, 208, 228, 0.8); /* Custom color during scale up */
  }
  100% {
    transform: scale(1);
    box-shadow: 0.3125rem 0.3125rem 0.3125rem 0.125rem rgba(0, 0, 0, 0.1); /* Return to default */
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Make sure this is above other content */
}

.loading-message {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #fafafa;
}

.loading-spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Lobby Container */
.lobby-state {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 100%;
}

/* Lobby Title */
.lobby-title {
  font-size: 2.25rem;
  color: #1e5799;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

/* Countdown Container */
.countdown-container {
  background-color: #e9f2fb;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items horizontally */
}

/* Countdown Title */
.countdown-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

/* Countdown Timer */
.countdown-timer {
  font-size: 1.875rem;
  font-weight: bold;
  color: #1e5799;
  margin-bottom: 20px;
}

/* Start Grouping Button */
.btn-start-grouping {
  background: linear-gradient(145deg, #6db3f2, #1e5799);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 20px; /* Add some space from the above elements */
}

.btn-start-grouping:hover {
  background-color: #154362;
  transform: scale(0.95);
}

/* Members Section */
.members-list {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 100%;
}

/* Members Prompt */
.members-prompt {
  font-size: 1.25rem;
  color: #555;
  margin-bottom: 20px;
}

/* Members Title */
.members-title {
  font-size: 1.875rem;
  font-weight: bold;
  color: #1e5799;
  margin-bottom: 10px;
}

/* Members List */
.members-list-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Members List Item */
.members-list-item {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 1.25rem;
  color: #333;
}

.btn-confirm-groups {
  background: linear-gradient(145deg, #f2746d, #991e1e);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  text-align: center;
  margin: 20px 0;
}

.live-grouping-indicator {
  font-size: 1.5rem;
  color: #1e5799;
  padding: 10px;
  background-color: #e0f7fa;
  border-radius: 10px;
  text-align: center;
  margin-top: 20px;
}

/* Add this CSS to your styles */
.save-reminder {
  background-color: red;
  color: white;
  padding: 10px;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  z-index: 1000;
}

.save-reminder.show {
  opacity: 1;
  transform: translateY(0);
}
